import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {User} from "../models/user/user";
import {Users} from "../models/user/users";
import {UserRegister} from "../models/user/user-register";
import {Credentials} from "../models/user/credentials";
import {Login} from "../models/user/login";
import {UserUpdate} from "../models/user/user-update";
import {ApiMessage} from "../models/api-message";
import {Email} from "../models/user/email";
import {PasswordReset} from "../models/user/password-reset";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public user!: User;
    public isLoading = false;
    public isLogged = false;
    public isAdmin = false;
    public isMember = false;
    public isUser = false;

    constructor(protected http: HttpClient,
                private route: ActivatedRoute,
                private router: Router) {
    }

    // get all users form API - only for admin
    public index(page?: number, perPage?: number, sort?: string, direction?: string, type?: string): Observable<Users> {

        let url = `${environment.api}/users`;

        if (perPage) url += `?page=${page}&perPage=${perPage}&sort=${sort}&direction=${direction}&type=${type}`;

        return this.http.get<Users>(url);
    }

    // register new user
    public register(data: UserRegister): Observable<User> {
        return this.http.post<User>(`${environment.api}/register`, data);
    }

    // user login
    public login(data: Login): Observable<Credentials> {
        const returnURL: string = this.route.snapshot.queryParamMap.get('returnURL') || '/secure';
        localStorage.setItem('returnURL', returnURL);
        return this.http.post<Credentials>(`${environment.api}/login`, data);
    }

    // get login user data
    public getUser(): void {
        this.isLoading = true;
        this.getUserAPIData().subscribe((user: User): void => {
            this.user = user;
            this.isLoading = false;
        })
    }

    // get specified user data from API - only admin/member
    public getUserData(id: number): Observable<User> {
        let url = '';
        switch (this.user.type) {
            case 'admin':
                url = `${environment.api}/admin-user-show`;
                break;
            case 'member':
                url = `${environment.api}/member-user-show`;
                break;
        }
        url += `/${id}`;
        return this.http.get<User>(url);
    }

    // update login user data
    public updateUserData(data: Partial<UserUpdate>): Observable<ApiMessage> {
        return this.http.put<ApiMessage>(`${environment.api}/update-user`, data);
    }

    // send email to the user for a password reset link
    public forgotPassword(data: Email): Observable<ApiMessage> {
        return this.http.post<ApiMessage>(`${environment.api}/forgot`, data);
    }

    // user set new password
    public setNewPassword(data: PasswordReset): Observable<ApiMessage> {
        return this.http.post<ApiMessage>(`${environment.api}/reset`, data);
    }

    // user logout
    public logout(returnURL: string): void {
        this.logoutAPI().subscribe((): void => {
            this.router.navigate([returnURL]).then((): boolean => this.isLogged = false);
            localStorage.removeItem('returnURL');
        });
    }

    // user logout from API
    public logoutAPI(): Observable<ApiMessage> {
        return this.http.post<ApiMessage>(`${environment.api}/logout`, {});
    }

    // get login user data from API
    private getUserAPIData(): Observable<User> {
        return this.http.get<User>(`${environment.api}/user`);
    }
}
