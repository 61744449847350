import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {


    constructor(private snackbar: MatSnackBar) {
    }

    public snackbarCustomComplete(message: string): void {
        this.snackbar.open(message, '', {panelClass: 'snackbar-success'});
    }

    public snackbarComplete(message: string): void {
        this.snackbar.open(
            `Congratulations, you have ${message}`,
            '', {panelClass: 'snackbar-success'});
    }

    public snackbarCustomError(message: string): void {
        this.snackbar.open(message, '', {panelClass: 'snackbar-error'});
    }

    public snackbarCustomWarning(message: string): void {
        this.snackbar.open(message, '', {panelClass: 'snackbar-warning'});
    }

    public snackbarAuthPassError(): void {
        this.snackbar.open('Invalid email/password or you don\'t have an account.',
            '', {panelClass: 'snackbar-error'});
    }

    public snackbarError(): void {
        this.snackbar.open('Error! Something went wrong.',
            '', {panelClass: 'snackbar-error'});
    }
}
